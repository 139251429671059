import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/hu/chapter_5/sub_2/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_5/sub_2/slide2';
import WrapperMobile from 'src/slides/mobile/hu/chapter_5/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="A csata, mely megmentette Európát és a világot | A varsói csata" lang="hu" description="A Varsó alatti siker Európának 20 év nyugalmat adott." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="A csata, mely megmentette Európát és a világot | A varsói csata" lang="hu" description="A Varsó alatti siker Európának 20 év nyugalmat adott." />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
